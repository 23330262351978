<template>
  <div data-bs-spy="scroll" data-bs-target="#navBar" id="weddingHome">
    <!-- Navbar start -->
    <div class="container-fluid sticky-top px-0">
      <div class="container-fluid">
        <div class="container px-0">
          <nav class="navbar navbar-light navbar-expand-xl" id="navBar">
            <a href="#" class="navbar-brand">
              <h4 class="text-primary display-6 fw-bold mb-0">
                Dr karen<strong class="text-secondary"> & </strong>Dr Heritier
              </h4>
            </a>
            <button
              class="navbar-toggler py-2 px-3"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarCollapse"
            >
              <span class="fa fa-bars text-primary"></span>
            </button>
            <div class="collapse navbar-collapse py-3" id="navbarCollapse">
              <div class="navbar-nav mx-auto border-top">
                <a href="#weddingHome" class="nav-item nav-link active">Home</a>
                <a href="#weddingAbout" class="nav-item nav-link">About</a>
                <a href="#weddingStory" class="nav-item nav-link">Story</a>
                <a href="#weddingTimeline" class="nav-item nav-link"
                  >Timeline</a
                >
                <a href="#weddingGallery" class="nav-item nav-link">Gallery</a>
                <a href="#weddingRsvp" class="nav-item nav-link">RSVP</a>
              </div>
              <div class="d-flex align-items-center flex-nowrap pt-xl-0">
                <a
                  href="#weddingRsvp"
                  class="btn btn-primary btn-primary-outline-0 py-2 px-4 ms-4"
                  >Book Attendance</a
                >
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>
    <!-- Navbar End -->

    <!-- Carousel Start -->
    <div class="container-fluid carousel-header px-0">
      <div id="carouselId" class="carousel slide" data-bs-ride="carousel">
        <ol class="carousel-indicators">
          <li
            data-bs-target="#carouselId"
            data-bs-slide-to="0"
            class="active"
          ></li>
          <li data-bs-target="#carouselId" data-bs-slide-to="1"></li>
          <li data-bs-target="#carouselId" data-bs-slide-to="2"></li>
        </ol>
        <div class="carousel-inner" role="listbox">
          <div class="carousel-item active">
            <img src="img/carousel-1.jpg" class="img-fluid" alt="Image" />
            <div class="carousel-caption">
              <div class="p-3 mx-auto animated zoomIn" style="max-width: 900px">
                <div
                  class="d-inline-block border-end-0 border-start-0 border-secondary p-2 mb-4"
                  style="border-style: double"
                >
                  <h4
                    class="text-white text-uppercase fw-bold mb-0"
                    style="letter-spacing: 3px"
                  >
                    WE ARE GETTING MARRIED
                  </h4>
                </div>
                <h1 class="display-1 text-capitalize text-white mb-3">
                  karen <i class="fa fa-heart text-primary"></i> Heritier
                </h1>
                <div
                  class="d-inline-block border-end-0 border-start-0 border-secondary p-2 mb-5"
                  style="border-style: double"
                >
                  <h4
                    class="text-white text-uppercase fw-bold mb-0"
                    style="letter-spacing: 3px"
                  >
                    September 22 2024
                  </h4>
                </div>
                <div class="d-flex align-items-center justify-content-center">
                  <a
                    class="btn btn-primary btn-primary-outline-0 py-3 px-5"
                    href="#"
                    >RSVP Now</a
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="carousel-item">
            <img src="img/carousel-2.jpg" class="img-fluid" alt="Image" />
            <div class="carousel-caption">
              <div class="p-3 mx-auto animated zoomIn" style="max-width: 900px">
                <div
                  class="d-inline-block border-end-0 border-start-0 border-secondary p-2 mb-5"
                  style="border-style: double"
                >
                  <h4
                    class="text-white text-uppercase fw-bold mb-0"
                    style="letter-spacing: 3px"
                  >
                    WE ARE GETTING MARRIED
                  </h4>
                </div>
                <h1 class="display-1 text-capitalize text-white mb-3">
                  karen <i class="fa fa-heart text-primary"></i> Heritier
                </h1>
                <div
                  class="d-inline-block border-end-0 border-start-0 border-secondary p-2 mb-4"
                  style="border-style: double"
                >
                  <h4
                    class="text-white text-uppercase fw-bold mb-0"
                    style="letter-spacing: 3px"
                  >
                    June 28 2024
                  </h4>
                </div>
                <div class="d-flex align-items-center justify-content-center">
                  <a
                    class="btn btn-primary btn-primary-outline-0 py-3 px-5"
                    href="#"
                    >RSVP Now</a
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="carousel-item">
            <img
              src="img/carousel-3.png"
              class="img-fluid bg-secondary"
              alt="Image"
            />
            <div class="carousel-caption">
              <div class="p-3 mx-auto animated zoomIn" style="max-width: 900px">
                <div
                  class="d-inline-block border-end-0 border-start-0 border-secondary p-2 mb-4"
                  style="border-style: double"
                >
                  <h4
                    class="text-white text-uppercase fw-bold mb-0"
                    style="letter-spacing: 3px"
                  >
                    WE ARE GETTING MARRIED
                  </h4>
                </div>
                <h1 class="display-1 text-capitalize text-white mb-3">
                  karen <i class="fa fa-heart text-primary"></i> Heritier
                </h1>
                <div
                  class="d-inline-block border-end-0 border-start-0 border-secondary p-2 mb-4"
                  style="border-style: double"
                >
                  <h4
                    class="text-white text-uppercase fw-bold mb-0"
                    style="letter-spacing: 3px"
                  >
                    June 28 2024
                  </h4>
                </div>
                <div class="d-flex align-items-center justify-content-center">
                  <a
                    class="btn btn-primary btn-primary-outline-0 py-3 px-5"
                    href="#"
                    >RSVP Now</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <button
          class="carousel-control-prev"
          type="button"
          data-bs-target="#carouselId"
          data-bs-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          type="button"
          data-bs-target="#carouselId"
          data-bs-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
    </div>
    <!-- Carousel End -->

    <!-- Hello! Start -->
    <div class="container-fluid position-relative py-5" id="weddingAbout">
      <div class="position-absolute" style="top: -35px; right: 0">
        <img src="img/tamp-bg-1.png" class="img-fluid" alt="" />
      </div>
      <div
        class="position-absolute"
        style="top: -10px; left: 0; transform: rotate(150deg)"
      >
        <img src="img/tamp-bg-1.png" class="img-fluid" alt="" />
      </div>
      <div class="container position-relative py-5">
        <div
          class="mx-auto text-center mb-5 wow fadeInUp"
          data-wow-delay="0.1s"
          style="max-width: 800"
        >
          <h1 class="text-primary display-1">Hello!</h1>
          <h4 class="text-dark mb-0">We invite you to celebrate our wedding</h4>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div class="row g-4 align-items-center">
              <div class="col-lg-5 wow fadeInUp" data-wow-delay="0.3s">
                <div class="d-flex">
                  <div class="text-end my-auto pe-4">
                    <h3 class="text-primary mb-3">karen</h3>
                    <p class="text-dark mb-0" style="line-height: 30px">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy
                    </p>
                  </div>
                  <img
                    src="img/bride.jpg"
                    class="img-fluid img-border"
                    alt=""
                  />
                </div>
              </div>
              <div class="col-lg-2 wow fadeIn" data-wow-delay="0.5s">
                <div class="d-flex align-items-center justify-content-center">
                  <i class="fa fa-heart fa-5x text-primary"></i>
                </div>
              </div>
              <div class="col-lg-5 wow fadeInUp" data-wow-delay="0.3s">
                <div class="d-flex">
                  <img
                    src="img/Groom.jpg"
                    class="img-fluid img-border"
                    alt=""
                  />
                  <div class="my-auto ps-4">
                    <h3 class="text-primary mb-3">Heritier</h3>
                    <p class="text-dark mb-0" style="line-height: 30px">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Hello! End -->

    <!-- About Start -->
    <div
      class="container-fluid position-relative overflow-hidden bg-secondary py-5"
    >
      <img
        src="img/bg-flower.png"
        class="img-fluid position-absolute top-0"
        style="right: -15px; transform: rotate(270deg); opacity: 0.5"
        alt=""
      />
      <img
        src="img/bg-flower.png"
        class="img-fluid position-absolute"
        style="
          bottom: 10px;
          left: -15px;
          transform: rotate(90deg);
          opacity: 0.5;
        "
        alt=""
      />
      <div class="container py-5 position-relative">
        <div class="row g-5 align-items-center">
          <div class="col-xl-6 wow fadeIn" data-wow-delay="0.1s">
            <div class="border-white bg-primary" style="border-style: double">
              <img src="img/about-1.jpg" class="img-fluid w-100" alt="" />
            </div>
          </div>
          <div class="col-xl-6">
            <div class="wow fadeIn" data-wow-delay="0.3s">
              <h5 class="text-uppercase text-primary fw-bold mb-4">About Us</h5>
              <h1 class="display-5 text-white mb-4">
                We Make Your Every Moment
              </h1>
              <p class="text-white fs-5 mb-4">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s,
              </p>
              <div class="tab-class bg-primary p-4">
                <ul class="nav d-flex mb-4">
                  <li class="nav-item">
                    <a
                      class="d-flex py-2 text-center bg-white active"
                      data-bs-toggle="pill"
                      href="#tab-1"
                    >
                      <span class="text-dark" style="width: 150px"
                        >Bride Info</span
                      >
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      class="d-flex py-2 mx-3 text-center bg-white"
                      data-bs-toggle="pill"
                      href="#tab-2"
                    >
                      <span class="text-dark" style="width: 150px"
                        >Groom info</span
                      >
                    </a>
                  </li>
                </ul>
                <div class="tab-content">
                  <div id="tab-1" class="tab-pane fade show p-0 active">
                    <div class="row">
                      <div class="col-12">
                        <div class="d-flex">
                          <div class="me-4">
                            <img
                              src="img/bride.jpg"
                              class="img-fluid w-100 img-border"
                              alt=""
                            />
                          </div>
                          <div class="text-start my-auto">
                            <h5 class="text-white fw-bold">karen</h5>
                            <p class="text-white mb-3">
                              Lorem Ipsum is simply dummy text of the printing
                              and typesetting industry.
                            </p>
                            <div class="d-flex">
                              <a
                                href=""
                                class="btn btn-primary btn-primary-outline-0 btn-sm-square me-2"
                                ><i class="fab fa-facebook-f"></i
                              ></a>
                              <a
                                href=""
                                class="btn btn-primary btn-primary-outline-0 btn-sm-square me-2"
                                ><i class="fab fa-twitter"></i
                              ></a>
                              <a
                                href=""
                                class="btn btn-primary btn-primary-outline-0 btn-sm-square me-2"
                                ><i class="fab fa-instagram"></i
                              ></a>
                              <a
                                href=""
                                class="btn btn-primary btn-primary-outline-0 btn-sm-square me-0"
                                ><i class="fab fa-linkedin-in"></i
                              ></a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="tab-2" class="tab-pane fade show p-0">
                    <div class="row">
                      <div class="col-12">
                        <div class="d-flex">
                          <div class="me-4">
                            <img
                              src="img/Groom.jpg"
                              class="img-fluid w-100 img-border"
                              alt=""
                            />
                          </div>
                          <div class="text-start my-auto">
                            <h5 class="text-white fw-bold">Heritier</h5>
                            <p class="text-white mb-3">
                              Lorem Ipsum is simply dummy text of the printing
                              and typesetting industry.
                            </p>
                            <div class="d-flex">
                              <a
                                href=""
                                class="btn btn-primary btn-primary-outline-0 btn-sm-square me-2"
                                ><i class="fab fa-facebook-f"></i
                              ></a>
                              <a
                                href=""
                                class="btn btn-primary btn-primary-outline-0 btn-sm-square me-2"
                                ><i class="fab fa-twitter"></i
                              ></a>
                              <a
                                href=""
                                class="btn btn-primary btn-primary-outline-0 btn-sm-square me-2"
                                ><i class="fab fa-instagram"></i
                              ></a>
                              <a
                                href=""
                                class="btn btn-primary btn-primary-outline-0 btn-sm-square me-0"
                                ><i class="fab fa-linkedin-in"></i
                              ></a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex mt-5">
                <div
                  class="d-flex align-items-center justify-content-center flex-shrink-0"
                >
                  <a
                    href="#"
                    class="btn btn-primary btn-primary-outline-0 py-3 px-4"
                    >Read More</a
                  >
                </div>
                <div class="d-flex align-items-center">
                  <div class="d-inline-block ms-4 me-3">
                    <i
                      class="fa fa-phone fa-2x text-success border border-2 border-white p-2"
                    ></i>
                  </div>
                  <div class="d-flex flex-column flex-nowrap">
                    <h5 class="text-dark fw-bold mb-2">Call Us Anytime</h5>
                    <h4 class="text-primary mb-0">+250782761525</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- About End -->

    <!-- Story Start -->
    <div class="container-fluid story position-relative py-5" id="weddingStory">
      <div class="position-absolute" style="top: -35px; right: 0">
        <img src="img/tamp-bg-1.png" class="img-fluid" alt="" />
      </div>
      <div
        class="position-absolute"
        style="top: -15px; left: 0; transform: rotate(150deg)"
      >
        <img src="img/tamp-bg-1.png" class="img-fluid" alt="" />
      </div>
      <div class="container position-relative py-5">
        <div class="text-center mb-5 wow fadeInUp" data-wow-delay="0.1s">
          <h5 class="text-uppercase text-primary fw-bold mb-4">Story</h5>
          <h1 class="display-4">Our Love Story</h1>
        </div>
        <div class="story-timeline">
          <div class="row wow fadeInUp" data-wow-delay="0.2s">
            <div
              class="col-md-6 text-end border-0 border-top border-end border-secondary p-4"
            >
              <div class="d-inline-flex align-items-center h-100">
                <img
                  src="img/story-1.jpg"
                  class="img-fluid w-100 img-border"
                  alt=""
                />
              </div>
            </div>
            <div
              class="col-md-6 border-start border-top border-secondary p-4 pe-0"
            >
              <div
                class="h-100 d-flex flex-column justify-content-center bg-secondary p-4"
              >
                <h4 class="mb-2 text-dark">First Meet</h4>
                <p
                  class="text-uppercase text-primary mb-2"
                  style="letter-spacing: 3px"
                >
                  01 Jan 2050
                </p>
                <p class="m-0 fs-5">
                  Lorem elitr magna stet rebum dolores sed. Est stet labore est
                  lorem lorem at amet sea, eos tempor rebum, labore amet ipsum
                  sea lorem, stet rebum eirmod amet. Kasd clita kasd stet amet
                  est dolor elitr.
                </p>
              </div>
            </div>
          </div>
          <div
            class="row flex-column-reverse flex-md-row wow fadeInUp"
            data-wow-delay="0.3s"
          >
            <div
              class="col-md-6 text-end border-end border-top border-secondary p-4 ps-0"
            >
              <div
                class="h-100 d-flex flex-column justify-content-center bg-secondary p-4"
              >
                <h4 class="text-dark mb-2">First Date</h4>
                <p
                  class="text-uppercase text-primary mb-2"
                  style="letter-spacing: 3px"
                >
                  01 Jan 2050
                </p>
                <p class="m-0 fs-5">
                  Lorem elitr magna stet rebum dolores sed. Est stet labore est
                  lorem lorem at amet sea, eos tempor rebum, labore amet ipsum
                  sea lorem, stet rebum eirmod amet. Kasd clita kasd stet amet
                  est dolor elitr.
                </p>
              </div>
            </div>
            <div class="col-md-6 border-start border-top border-secondary p-4">
              <div class="d-inline-flex align-items-center h-100">
                <img
                  src="img/story-2.jpg"
                  class="img-fluid w-100 img-border"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div class="row wow fadeInUp" data-wow-delay="0.4s">
            <div
              class="col-md-6 text-end border-end border-top border-secondary p-4 ps-0"
            >
              <div class="d-inline-flex align-items-center h-100">
                <img
                  src="img/story-3.jpg"
                  class="img-fluid w-100 img-border"
                  alt=""
                />
              </div>
            </div>
            <div
              class="col-md-6 border-start border-top border-secondary p-4 pe-0"
            >
              <div
                class="h-100 d-flex flex-column justify-content-center bg-secondary p-4"
              >
                <h4 class="text-dark mb-2">Proposal</h4>
                <p
                  class="text-uppercase text-primary mb-2"
                  style="letter-spacing: 3px"
                >
                  01 Jan 2050
                </p>
                <p class="m-0 fs-5">
                  Lorem elitr magna stet rebum dolores sed. Est stet labore est
                  lorem lorem at amet sea, eos tempor rebum, labore amet ipsum
                  sea lorem, stet rebum eirmod amet. Kasd clita kasd stet amet
                  est dolor elitr.
                </p>
              </div>
            </div>
          </div>
          <div
            class="row flex-column-reverse flex-md-row wow fadeInUp"
            data-wow-delay="0.5s"
          >
            <div
              class="col-md-6 text-end border border-start-0 border-secondary p-4 ps-0"
            >
              <div
                class="h-100 d-flex flex-column justify-content-center bg-secondary p-4"
              >
                <h4 class="text-dark mb-2">Enagagement</h4>
                <p
                  class="text-uppercase text-primary mb-2"
                  style="letter-spacing: 3px"
                >
                  01 Jan 2050
                </p>
                <p class="m-0 fs-5">
                  Lorem elitr magna stet rebum dolores sed. Est stet labore est
                  lorem lorem at amet sea, eos tempor rebum, labore amet ipsum
                  sea lorem, stet rebum eirmod amet. Kasd clita kasd stet amet
                  est dolor elitr.
                </p>
              </div>
            </div>
            <div class="col-md-6 border border-end-0 border-secondary p-4">
              <div class="d-inline-flex align-items-center h-100">
                <img
                  src="img/story-4.jpg"
                  class="img-fluid w-100 img-border"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Story End -->

    <!--- Wedding Date -->
    <div class="container-fluid wedding-date-bg position-relative py-5">
      <div class="position-absolute" style="top: -100px; right: 0">
        <img src="img/tamp-bg-1.png" class="img-fluid" alt="" />
      </div>
      <div
        class="position-absolute"
        style="top: -80px; left: 0; transform: rotate(150deg)"
      >
        <img src="img/tamp-bg-1.png" class="img-fluid" alt="" />
      </div>
      <div class="container py-5 wow zoomIn" data-wow-delay="0.1s">
        <div
          class="wedding-date text-center bg-light p-5"
          style="
            border-style: double !important;
            border: 15px solid rgba(253, 93, 93, 0.5);
          "
        >
          <div class="wedding-date-content">
            <div
              class="d-inline-block border-end-0 border-start-0 border-secondary p-2 mb-4"
              style="border-style: double"
            >
              <h4
                class="text-dark text-uppercase fw-bold mb-0"
                style="letter-spacing: 3px"
              >
                September 22, 2024
              </h4>
            </div>
            <h1 class="display-4">We Are Getting Married</h1>
            <p class="text-dark fs-5">
              Kimihurura, Kigali Rwanda
            </p>
            <div class="d-flex align-items-center justify-content-center mb-5">
              <div class="text-dark fs-2 me-4">
                <div>00</div>
                <span>Days</span>
              </div>
              <div class="text-dark fs-2 me-4">
                <div>00</div>
                <span>Hours</span>
              </div>
              <div class="text-dark fs-2 me-4">
                <div>00</div>
                <span>Mins</span>
              </div>
              <div class="text-dark fs-2 me-0">
                <div>00</div>
                <span>Secs</span>
              </div>
            </div>
            <a class="btn btn-primary btn-primary-outline-0 py-3 px-5" href="#"
              >Book Your Attendance</a
            >
          </div>
          <div
            class="position-absolute"
            style="
              top: 15%;
              right: -30px;
              transform: rotate(320deg);
              opacity: 0.5;
              z-index: 1;
            "
          >
            <img src="img/attendance-bg.png" class="img-fluid" alt="" />
          </div>
          <div
            class="position-absolute"
            style="
              top: 15%;
              left: -10px;
              transform: rotate(-320deg);
              opacity: 0.5;
              z-index: 1;
            "
          >
            <img src="img/attendance-bg.png" class="img-fluid" alt="" />
          </div>
        </div>
      </div>
    </div>
    <!-- Wedding Date -->

    <!-- Wedding Timeline start -->
    <div
      class="container-fluid wedding-timeline bg-secondary position-relative overflow-hidden py-5"
      id="weddingTimeline"
    >
      <div
        class="position-absolute"
        style="
          top: 50%;
          left: -100px;
          transform: translateY(-50%);
          opacity: 0.5;
        "
      >
        <img src="img/wedding-bg.png" class="img-fluid" alt="" />
      </div>
      <div
        class="position-absolute"
        style="
          top: 50%;
          right: -160px;
          transform: translateY(-50%);
          opacity: 0.5;
          rotate: 335deg;
        "
      >
        <img src="img/wedding-bg.png" class="img-fluid" alt="" />
      </div>
      <div class="container py-5">
        <div class="text-center mb-5">
          <h1 class="display-4 text-white">Wedding Planing Timeline</h1>
        </div>
        <div class="position-relative wedding-content">
          <div class="row g-4">
            <div class="col-6 col-md-6 col-xl-3 border border-bottom-0">
              <div class="text-center p-3 wow fadeIn" data-wow-delay="0.1s">
                <div class="mb-4 p-3 d-inline-flex">
                  <i class="fas fa-menorah text-primary fa-3x"></i>
                </div>
                <p class="text-primary">10:00AM - 11:00AM</p>
                <h3 class="text-dark">Dinner</h3>
                <p class="text-dark">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy
                </p>
              </div>
            </div>
            <div
              class="col-6 col-md-6 col-xl-3 border border-top-0 border-start-0"
            >
              <div class="text-center p-3 wow fadeIn" data-wow-delay="0.3s">
                <div class="mb-4 p-3 d-inline-flex">
                  <i class="fas fa-photo-video text-primary fa-3x"></i>
                </div>
                <p class="text-primary">10:00AM - 11:00AM</p>
                <h3 class="text-dark">Photoshoot</h3>
                <p class="text-dark">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy
                </p>
              </div>
            </div>
            <div
              class="col-6 col-md-6 col-xl-3 border border-bottom-0 border-end-0"
            >
              <div class="text-center p-3 wow fadeIn" data-wow-delay="0.5s">
                <div class="mb-4 p-3 d-inline-flex">
                  <i class="fas fa-dungeon text-primary fa-3x"></i>
                </div>
                <p class="text-primary">10:00AM - 11:00AM</p>
                <h3 class="text-dark">Reception</h3>
                <p class="text-dark">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy
                </p>
              </div>
            </div>
            <div class="col-6 col-md-6 col-xl-3 border border-top-0">
              <div class="text-center p-3 wow fadeIn" data-wow-delay="0.7s">
                <div class="mb-4 p-3 d-inline-flex">
                  <i class="fas fa-ring text-primary fa-3x"></i>
                </div>
                <p class="text-primary">10:00AM - 11:00AM</p>
                <h3 class="text-dark">Ceremony</h3>
                <p class="text-dark">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy
                </p>
              </div>
            </div>
          </div>
          <div
            class="position-absolute heart-circle"
            style="bottom: 0; left: -18px"
          >
            <div
              class="border border-2 border-primary rounded-circle p-1 bg-secondary"
            ></div>
          </div>
          <div
            class="position-absolute heart-circle"
            style="top: 18px; right: -17px"
          >
            <div
              class="border border-2 border-primary rounded-circle p-1 bg-secondary"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <!-- Wedding Timeline End -->

    <!-- Gallery Start -->
    <div
      class="container-fluid gallery position-relative py-5"
      id="weddingGallery"
    >
      <div class="position-absolute" style="top: -95px; right: 0">
        <img src="img/tamp-bg-1.png" class="img-fluid" alt="" />
      </div>
      <div
        class="position-absolute"
        style="top: -80px; left: 0; transform: rotate(150deg)"
      >
        <img src="img/tamp-bg-1.png" class="img-fluid" alt="" />
      </div>
      <div class="container position-relative py-5">
        <div
          class="text-center mx-auto pb-5 wow fadeInUp"
          data-wow-delay="0.1s"
          style="max-width: 800px"
        >
          <h1 class="display-2 text-dark">Wedding Gallery</h1>
          <p class="fs-5 text-dark">
            Lorem Ipsum is simply dummy text of the printing and typesetting
          </p>
        </div>
        <div class="row g-4">
          <div
            class="col-md-6 col-lg-4 col-xl-3 wow fadeInUp"
            data-wow-delay="0.2s"
          >
            <div class="gallery-item">
              <div class="gallery-img">
                <img class="img-fluid w-100" src="img/gallery-1.jpg" alt="" />
                <div class="hover-style"></div>
                <div class="search-icon">
                  <a
                    href="img/gallery-1.jpg"
                    data-lightbox="Gallery-1"
                    class="my-auto"
                    ><i
                      class="fas fa-plus btn-primary btn-primary-outline-0 p-3"
                    ></i
                  ></a>
                </div>
              </div>
              <div
                class="gallery-overlay bg-light border-secondary border-top-0 p-4"
                style="border-style: double"
              >
                <h5>Wedding on the beach</h5>
                <p class="text-dark mb-0">June 28, 2024</p>
              </div>
            </div>
          </div>
          <div
            class="col-md-6 col-lg-4 col-xl-3 wow fadeInUp"
            data-wow-delay="0.4s"
          >
            <div class="gallery-item">
              <div class="gallery-img">
                <img class="img-fluid w-100" src="img/gallery-2.jpg" alt="" />
                <div class="hover-style"></div>
                <div class="search-icon">
                  <a
                    href="img/gallery-2.jpg"
                    data-lightbox="Gallery-2"
                    class="my-auto"
                    ><i
                      class="fas fa-plus btn-primary btn-primary-outline-0 p-3"
                    ></i
                  ></a>
                </div>
              </div>
              <div
                class="gallery-overlay bg-light border-secondary border-top-0 p-4"
                style="border-style: double"
              >
                <h5>Wedding on the beach</h5>
                <p class="text-dark mb-0">June 28, 2024</p>
              </div>
            </div>
          </div>
          <div
            class="col-md-6 col-lg-4 col-xl-3 wow fadeInUp"
            data-wow-delay="0.6s"
          >
            <div class="gallery-item">
              <div class="gallery-img">
                <img class="img-fluid w-100" src="img/gallery-3.jpg" alt="" />
                <div class="hover-style"></div>
                <div class="search-icon">
                  <a
                    href="img/gallery-3.jpg"
                    data-lightbox="Gallery-3"
                    class="my-auto"
                    ><i
                      class="fas fa-plus btn-primary btn-primary-outline-0 p-3"
                    ></i
                  ></a>
                </div>
              </div>
              <div
                class="gallery-overlay bg-light border-secondary border-top-0 p-4"
                style="border-style: double"
              >
                <h5>Wedding on the beach</h5>
                <p class="text-dark mb-0">June 28, 2024</p>
              </div>
            </div>
          </div>
          <div
            class="col-md-6 col-lg-4 col-xl-3 wow fadeInUp"
            data-wow-delay="0.8s"
          >
            <div class="gallery-item">
              <div class="gallery-img">
                <img class="img-fluid w-100" src="img/gallery-4.jpg" alt="" />
                <div class="hover-style"></div>
                <div class="search-icon">
                  <a
                    href="img/gallery-4.jpg"
                    data-lightbox="Gallery-4"
                    class="my-auto"
                    ><i
                      class="fas fa-plus btn-primary btn-primary-outline-0 p-3"
                    ></i
                  ></a>
                </div>
              </div>
              <div
                class="gallery-overlay bg-light border-secondary border-top-0 p-4"
                style="border-style: double"
              >
                <h5>Wedding on the beach</h5>
                <p class="text-dark mb-0">June 28, 2024</p>
              </div>
            </div>
          </div>
          <div
            class="col-md-6 col-lg-4 col-xl-3 wow fadeInUp"
            data-wow-delay="0.2s"
          >
            <div class="gallery-item">
              <div class="gallery-img">
                <img class="img-fluid w-100" src="img/gallery-5.jpg" alt="" />
                <div class="hover-style"></div>
                <div class="search-icon">
                  <a
                    href="img/gallery-5.jpg"
                    data-lightbox="Gallery-5"
                    class="my-auto"
                    ><i
                      class="fas fa-plus btn-primary btn-primary-outline-0 p-3"
                    ></i
                  ></a>
                </div>
              </div>
              <div
                class="gallery-overlay bg-light border-secondary border-top-0 p-4"
                style="border-style: double"
              >
                <h5>Wedding on the beach</h5>
                <p class="text-dark mb-0">June 28, 2024</p>
              </div>
            </div>
          </div>
          <div
            class="col-md-6 col-lg-4 col-xl-3 wow fadeInUp"
            data-wow-delay="0.4s"
          >
            <div class="gallery-item">
              <div class="gallery-img">
                <img class="img-fluid w-100" src="img/gallery-6.jpg" alt="" />
                <div class="hover-style"></div>
                <div class="search-icon">
                  <a
                    href="img/gallery-6.jpg"
                    data-lightbox="Gallery-6"
                    class="my-auto"
                    ><i
                      class="fas fa-plus btn-primary btn-primary-outline-0 p-3"
                    ></i
                  ></a>
                </div>
              </div>
              <div
                class="gallery-overlay bg-light border-secondary border-top-0 p-4"
                style="border-style: double"
              >
                <h5>Wedding on the beach</h5>
                <p class="text-dark mb-0">June 28, 2024</p>
              </div>
            </div>
          </div>
          <div
            class="col-md-6 col-lg-4 col-xl-3 wow fadeInUp"
            data-wow-delay="0.6s"
          >
            <div class="gallery-item">
              <div class="gallery-img">
                <img class="img-fluid w-100" src="img/gallery-7.jpg" alt="" />
                <div class="hover-style"></div>
                <div class="search-icon">
                  <a
                    href="img/gallery-7.jpg"
                    data-lightbox="Gallery-7"
                    class="my-auto"
                    ><i
                      class="fas fa-plus btn-primary btn-primary-outline-0 p-3"
                    ></i
                  ></a>
                </div>
              </div>
              <div
                class="gallery-overlay bg-light border-secondary border-top-0 p-4"
                style="border-style: double"
              >
                <h5>Wedding on the beach</h5>
                <p class="text-dark mb-0">June 28, 2024</p>
              </div>
            </div>
          </div>
          <div
            class="col-md-6 col-lg-4 col-xl-3 wow fadeInUp"
            data-wow-delay="0.8s"
          >
            <div class="gallery-item">
              <div class="gallery-img">
                <img class="img-fluid w-100" src="img/gallery-8.jpg" alt="" />
                <div class="hover-style"></div>
                <div class="search-icon">
                  <a
                    href="img/gallery-8.jpg"
                    data-lightbox="Gallery-8"
                    class="my-auto"
                    ><i
                      class="fas fa-plus btn-primary btn-primary-outline-0 p-3"
                    ></i
                  ></a>
                </div>
              </div>
              <div
                class="gallery-overlay bg-light border-secondary border-top-0 p-4"
                style="border-style: double"
              >
                <h5>Wedding on the beach</h5>
                <p class="text-dark mb-0">June 28, 2024</p>
              </div>
            </div>
          </div>
          <div class="col-12 text-center wow fadeIn" data-wow-delay="0.2s">
            <a
              class="btn btn-primary btn-primary-outline-0 py-3 px-5 me-2"
              href="#"
              >View All <i class="fas fa-arrow-right"></i
            ></a>
          </div>
        </div>
      </div>
    </div>
    <!-- Gallery end -->

    <!--- Bridesmaids & Groomsmen start -->
    <div class="container-fluid team position-relative py-5">
      <div class="position-absolute" style="bottom: -80px; right: 0">
        <img src="img/tamp-bg-1.png" class="img-fluid" alt="" />
      </div>
      <div
        class="position-absolute"
        style="bottom: -90px; left: 0; transform: rotate(150deg)"
      >
        <img src="img/tamp-bg-1.png" class="img-fluid" alt="" />
      </div>
      <div class="position-absolute" style="top: -100px; right: 0">
        <img src="img/tamp-bg-1.png" class="img-fluid" alt="" />
      </div>
      <div
        class="position-absolute"
        style="top: -80px; left: 0; transform: rotate(150deg)"
      >
        <img src="img/tamp-bg-1.png" class="img-fluid" alt="" />
      </div>
      <div class="container py-5">
        <div
          class="mb-5 text-center mx-auto wow fadeInUp"
          data-wow-delay="0.1s"
          style="max-width: 800px"
        >
          <h1 class="display-2 text-dark">Bridesmaids & Groomsmen</h1>
          <p class="fs-5 text-dark">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            Ipsum is simply dummy text of the printing and typesetting Ipsum is
            simply dummy text of the printing and typesetting
          </p>
        </div>
        <div class="row g-4">
          <div
            class="col-md-6 col-lg-6 col-xl-3 wow fadeInUp"
            data-wow-delay="0.2s"
          >
            <div class="team-item">
              <div class="team-img">
                <div class="team-img-main">
                  <img
                    src="img/bridesmaid-1.png"
                    class="img-fluid w-100"
                    alt=""
                  />
                </div>
                <div
                  class="team-img-bg position-absolute"
                  style="
                    bottom: -30px;
                    left: -100px;
                    transform: rotate(50deg);
                    z-index: 1;
                  "
                >
                  <img src="img/team-1-bg.png" class="img-fluid" alt="" />
                </div>
                <div
                  class="team-img-bg position-absolute"
                  style="
                    bottom: -40px;
                    right: -120px;
                    transform: rotate(-30deg);
                    z-index: 1;
                  "
                >
                  <img src="img/team-1-bg.png" class="img-fluid" alt="" />
                </div>
              </div>
              <div class="team-content">
                <div class="d-flex flex-column p-4">
                  <h5 class="text-white display-6 mb-1">Amelia Alex</h5>
                  <h5 class="text-white fs-5 mb-0">Bridesmaid</h5>
                </div>
              </div>
              <div class="team-social d-flex flex-column">
                <a class="btn btn-light btn-light-outline-0 mb-2" href="#"
                  ><i class="fab fa-twitter"></i
                ></a>
                <a class="btn btn-light btn-light-outline-0 mb-2" href="#"
                  ><i class="fab fa-facebook-f"></i
                ></a>
                <a class="btn btn-light btn-light-outline-0 mb-2" href="#"
                  ><i class="fab fa-linkedin-in"></i
                ></a>
                <a class="btn btn-light btn-light-outline-0" href="#"
                  ><i class="fab fa-instagram"></i
                ></a>
              </div>
            </div>
          </div>
          <div
            class="col-md-6 col-lg-6 col-xl-3 wow fadeInUp"
            data-wow-delay="0.4s"
          >
            <div class="team-item">
              <div class="team-img">
                <div class="team-img-main">
                  <img
                    src="img/bridesmaid-team-2.png"
                    class="img-fluid w-100"
                    alt=""
                  />
                </div>
                <div
                  class="team-img-bg position-absolute"
                  style="
                    bottom: -30px;
                    left: -100px;
                    transform: rotate(50deg);
                    z-index: 1;
                  "
                >
                  <img src="img/team-1-bg.png" class="img-fluid" alt="" />
                </div>
                <div
                  class="team-img-bg position-absolute"
                  style="
                    bottom: -40px;
                    right: -120px;
                    transform: rotate(-30deg);
                    z-index: 1;
                  "
                >
                  <img src="img/team-1-bg.png" class="img-fluid" alt="" />
                </div>
              </div>
              <div class="team-content">
                <div class="d-flex flex-column p-4">
                  <h5 class="text-white display-6 mb-1">Amelia Alex</h5>
                  <h5 class="text-white fs-5 mb-0">Bridesmaid</h5>
                </div>
              </div>
              <div class="team-social d-flex flex-column">
                <a class="btn btn-light btn-light-outline-0 mb-2" href="#"
                  ><i class="fab fa-twitter"></i
                ></a>
                <a class="btn btn-light btn-light-outline-0 mb-2" href="#"
                  ><i class="fab fa-facebook-f"></i
                ></a>
                <a class="btn btn-light btn-light-outline-0 mb-2" href="#"
                  ><i class="fab fa-linkedin-in"></i
                ></a>
                <a class="btn btn-light btn-light-outline-0" href="#"
                  ><i class="fab fa-instagram"></i
                ></a>
              </div>
            </div>
          </div>
          <div
            class="col-md-6 col-lg-6 col-xl-3 wow fadeInUp"
            data-wow-delay="0.6s"
          >
            <div class="team-item">
              <div class="team-img">
                <div class="team-img-main">
                  <img
                    src="img/bridesmaid-3.png"
                    class="img-fluid w-100"
                    alt=""
                  />
                </div>
                <div
                  class="team-img-bg position-absolute"
                  style="
                    bottom: -30px;
                    left: -100px;
                    transform: rotate(50deg);
                    z-index: 1;
                  "
                >
                  <img src="img/team-1-bg.png" class="img-fluid" alt="" />
                </div>
                <div
                  class="team-img-bg position-absolute"
                  style="
                    bottom: -40px;
                    right: -120px;
                    transform: rotate(-30deg);
                    z-index: 1;
                  "
                >
                  <img src="img/team-1-bg.png" class="img-fluid" alt="" />
                </div>
              </div>
              <div class="team-content">
                <div class="d-flex flex-column p-4">
                  <h5 class="text-white display-6 mb-1">Amelia Alex</h5>
                  <h5 class="text-white fs-5 mb-0">Bridesmaid</h5>
                </div>
              </div>
              <div class="team-social d-flex flex-column">
                <a class="btn btn-light btn-light-outline-0 mb-2" href="#"
                  ><i class="fab fa-twitter"></i
                ></a>
                <a class="btn btn-light btn-light-outline-0 mb-2" href="#"
                  ><i class="fab fa-facebook-f"></i
                ></a>
                <a class="btn btn-light btn-light-outline-0 mb-2" href="#"
                  ><i class="fab fa-linkedin-in"></i
                ></a>
                <a class="btn btn-light btn-light-outline-0" href="#"
                  ><i class="fab fa-instagram"></i
                ></a>
              </div>
            </div>
          </div>
          <div
            class="col-md-6 col-lg-6 col-xl-3 wow fadeInUp"
            data-wow-delay="0.8s"
          >
            <div class="team-item">
              <div class="team-img">
                <div class="team-img-main">
                  <img
                    src="img/bridesmaid-4.png"
                    class="img-fluid w-100"
                    alt=""
                  />
                </div>
                <div
                  class="team-img-bg position-absolute"
                  style="
                    bottom: -30px;
                    left: -100px;
                    transform: rotate(50deg);
                    z-index: 1;
                  "
                >
                  <img src="img/team-1-bg.png" class="img-fluid" alt="" />
                </div>
                <div
                  class="team-img-bg position-absolute"
                  style="
                    bottom: -40px;
                    right: -120px;
                    transform: rotate(-30deg);
                    z-index: 1;
                  "
                >
                  <img src="img/team-1-bg.png" class="img-fluid" alt="" />
                </div>
              </div>
              <div class="team-content">
                <div class="d-flex flex-column p-4">
                  <h5 class="text-white display-6 mb-1">Amelia Alex</h5>
                  <h5 class="text-white fs-5 mb-0">Bridesmaid</h5>
                </div>
              </div>
              <div class="team-social d-flex flex-column">
                <a class="btn btn-light btn-light-outline-0 mb-2" href="#"
                  ><i class="fab fa-twitter"></i
                ></a>
                <a class="btn btn-light btn-light-outline-0 mb-2" href="#"
                  ><i class="fab fa-facebook-f"></i
                ></a>
                <a class="btn btn-light btn-light-outline-0 mb-2" href="#"
                  ><i class="fab fa-linkedin-in"></i
                ></a>
                <a class="btn btn-light btn-light-outline-0" href="#"
                  ><i class="fab fa-instagram"></i
                ></a>
              </div>
            </div>
          </div>
          <div
            class="col-md-6 col-lg-6 col-xl-3 wow fadeInUp"
            data-wow-delay="0.2s"
          >
            <div class="team-item">
              <div class="team-img">
                <div class="team-img-main">
                  <img
                    src="img/Groomsmen-1.png"
                    class="img-fluid w-100"
                    alt=""
                  />
                </div>
                <div
                  class="team-img-bg position-absolute"
                  style="
                    bottom: -30px;
                    left: -100px;
                    transform: rotate(50deg);
                    z-index: 1;
                  "
                >
                  <img src="img/team-1-bg.png" class="img-fluid" alt="" />
                </div>
                <div
                  class="team-img-bg position-absolute"
                  style="
                    bottom: -40px;
                    right: -120px;
                    transform: rotate(-30deg);
                    z-index: 1;
                  "
                >
                  <img src="img/team-1-bg.png" class="img-fluid" alt="" />
                </div>
              </div>
              <div class="team-content">
                <div class="d-flex flex-column p-4">
                  <h5 class="text-white display-6 mb-1">David John</h5>
                  <h5 class="text-white fs-5 mb-0">Groomsmen</h5>
                </div>
              </div>
              <div class="team-social d-flex flex-column">
                <a class="btn btn-light btn-light-outline-0 mb-2" href="#"
                  ><i class="fab fa-twitter"></i
                ></a>
                <a class="btn btn-light btn-light-outline-0 mb-2" href="#"
                  ><i class="fab fa-facebook-f"></i
                ></a>
                <a class="btn btn-light btn-light-outline-0 mb-2" href="#"
                  ><i class="fab fa-linkedin-in"></i
                ></a>
                <a class="btn btn-light btn-light-outline-0" href="#"
                  ><i class="fab fa-instagram"></i
                ></a>
              </div>
            </div>
          </div>
          <div
            class="col-md-6 col-lg-6 col-xl-3 wow fadeInUp"
            data-wow-delay="0.4s"
          >
            <div class="team-item">
              <div class="team-img">
                <div class="team-img-main">
                  <img
                    src="img/Groomsmen-2.png"
                    class="img-fluid w-100"
                    alt=""
                  />
                </div>
                <div
                  class="team-img-bg position-absolute"
                  style="
                    bottom: -30px;
                    left: -100px;
                    transform: rotate(50deg);
                    z-index: 1;
                  "
                >
                  <img src="img/team-1-bg.png" class="img-fluid" alt="" />
                </div>
                <div
                  class="team-img-bg position-absolute"
                  style="
                    bottom: -40px;
                    right: -120px;
                    transform: rotate(-30deg);
                    z-index: 1;
                  "
                >
                  <img src="img/team-1-bg.png" class="img-fluid" alt="" />
                </div>
              </div>
              <div class="team-content">
                <div class="d-flex flex-column p-4">
                  <h5 class="text-white display-6 mb-1">David John</h5>
                  <h5 class="text-white fs-5 mb-0">Groomsmen</h5>
                </div>
              </div>
              <div class="team-social d-flex flex-column">
                <a class="btn btn-light btn-light-outline-0 mb-2" href="#"
                  ><i class="fab fa-twitter"></i
                ></a>
                <a class="btn btn-light btn-light-outline-0 mb-2" href="#"
                  ><i class="fab fa-facebook-f"></i
                ></a>
                <a class="btn btn-light btn-light-outline-0 mb-2" href="#"
                  ><i class="fab fa-linkedin-in"></i
                ></a>
                <a class="btn btn-light btn-light-outline-0" href="#"
                  ><i class="fab fa-instagram"></i
                ></a>
              </div>
            </div>
          </div>
          <div
            class="col-md-6 col-lg-6 col-xl-3 wow fadeInUp"
            data-wow-delay="0.6s"
          >
            <div class="team-item">
              <div class="team-img">
                <div class="team-img-main">
                  <img
                    src="img/Groomsmen-3.png"
                    class="img-fluid w-100"
                    alt=""
                  />
                </div>
                <div
                  class="team-img-bg position-absolute"
                  style="
                    bottom: -30px;
                    left: -100px;
                    transform: rotate(50deg);
                    z-index: 1;
                  "
                >
                  <img src="img/team-1-bg.png" class="img-fluid" alt="" />
                </div>
                <div
                  class="team-img-bg position-absolute"
                  style="
                    bottom: -40px;
                    right: -120px;
                    transform: rotate(-30deg);
                    z-index: 1;
                  "
                >
                  <img src="img/team-1-bg.png" class="img-fluid" alt="" />
                </div>
              </div>
              <div class="team-content">
                <div class="d-flex flex-column p-4">
                  <h5 class="text-white display-6 mb-1">David John</h5>
                  <h5 class="text-white fs-5 mb-0">Groomsmen</h5>
                </div>
              </div>
              <div class="team-social d-flex flex-column">
                <a class="btn btn-light btn-light-outline-0 mb-2" href="#"
                  ><i class="fab fa-twitter"></i
                ></a>
                <a class="btn btn-light btn-light-outline-0 mb-2" href="#"
                  ><i class="fab fa-facebook-f"></i
                ></a>
                <a class="btn btn-light btn-light-outline-0 mb-2" href="#"
                  ><i class="fab fa-linkedin-in"></i
                ></a>
                <a class="btn btn-light btn-light-outline-0" href="#"
                  ><i class="fab fa-instagram"></i
                ></a>
              </div>
            </div>
          </div>
          <div
            class="col-md-6 col-lg-6 col-xl-3 wow fadeInUp"
            data-wow-delay="0.8s"
          >
            <div class="team-item">
              <div class="team-img">
                <div class="team-img-main">
                  <img
                    src="img/Groomsmen-4.png"
                    class="img-fluid w-100"
                    alt=""
                  />
                </div>
                <div
                  class="team-img-bg position-absolute"
                  style="
                    bottom: -30px;
                    left: -100px;
                    transform: rotate(50deg);
                    z-index: 1;
                  "
                >
                  <img src="img/team-1-bg.png" class="img-fluid" alt="" />
                </div>
                <div
                  class="team-img-bg position-absolute"
                  style="
                    bottom: -40px;
                    right: -120px;
                    transform: rotate(-30deg);
                    z-index: 1;
                  "
                >
                  <img src="img/team-1-bg.png" class="img-fluid" alt="" />
                </div>
              </div>
              <div class="team-content">
                <div class="d-flex flex-column p-4">
                  <h5 class="text-white display-6 mb-1">David John</h5>
                  <h5 class="text-white fs-5 mb-0">Groomsmen</h5>
                </div>
              </div>
              <div class="team-social d-flex flex-column">
                <a class="btn btn-light btn-light-outline-0 mb-2" href="#"
                  ><i class="fab fa-twitter"></i
                ></a>
                <a class="btn btn-light btn-light-outline-0 mb-2" href="#"
                  ><i class="fab fa-facebook-f"></i
                ></a>
                <a class="btn btn-light btn-light-outline-0 mb-2" href="#"
                  ><i class="fab fa-linkedin-in"></i
                ></a>
                <a class="btn btn-light btn-light-outline-0" href="#"
                  ><i class="fab fa-instagram"></i
                ></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Bridesmaids & Groomsmen End -->

    <!-- RSVP Form Start -->
    <div class="container-fluid RSVP-form py-5" id="weddingRsvp">
      <div class="container py-5">
        <div
          class="mb-5 text-center mx-auto wow fadeIn"
          data-wow-delay="0.1s"
          style="max-width: 800px"
        >
          <h1 class="display-2 text-primary">RSVP Form</h1>
        </div>
        <div class="row justify-content-center">
          <div class="col-md-10">
            <div
              class="p-5 border-secondary position-relative"
              style="border-style: double"
            >
              <div
                class="fw-bold text-primary bg-white d-flex align-items-center justify-content-center position-absolute border-secondary p-2 wow fadeIn"
                data-wow-delay="0.1s"
                style="
                  width: 75%;
                  border-style: double;
                  top: 0;
                  left: 50%;
                  transform: translate(-50%, -50%);
                "
              >
                Kindly respond by September 22, 2024 we look forward to
                celebrating with you!
              </div>
              <form>
                <div class="row gx-4 gy-3">
                  <div class="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
                    <div class="form-group">
                      <label for="Examplename" class="form-label text-dark"
                        >First Name</label
                      >
                      <input
                        type="text"
                        class="form-control py-3 border-0"
                        id="Examplename"
                        placeholder="First Name"
                      />
                    </div>
                  </div>
                  <div class="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
                    <div class="form-group">
                      <label for="Examplename" class="form-label text-dark"
                        >Last Name</label
                      >
                      <input
                        type="text"
                        class="form-control py-3 border-0"
                        id="Examplename"
                        placeholder="Last Name"
                      />
                    </div>
                  </div>
                  <div class="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
                    <div class="form-group">
                      <label for="Examplename" class="form-label text-dark"
                        >Enter Your Email</label
                      >
                      <input
                        type="email"
                        class="form-control py-3 border-0"
                        id="Examplename"
                        placeholder="Enter Your Email"
                      />
                    </div>
                  </div>
                  <div class="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
                    <div class="form-group">
                      <label for="Examplename" class="form-label text-dark"
                        >Enter Your Phone</label
                      >
                      <input
                        type="text"
                        class="form-control py-3 border-0"
                        id="Examplename"
                        placeholder="Enter Your Phone"
                      />
                    </div>
                  </div>
                  <div class="col-12 wow fadeIn" data-wow-delay="0.1s">
                    <div class="form-group">
                      <label for="exampleselect" class="form-label text-dark"
                        >Number Of Guests</label
                      >
                      <select
                        class="form-control bg-white text-dark py-3 border-0"
                        id="exampleselect"
                        placeholder="Number Of Guests"
                      >
                        <option>Only Me</option>
                        <option>02 Guests</option>
                        <option>03 Guests</option>
                        <option>04 Guests</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-12 wow fadeIn" data-wow-delay="0.1s">
                    <div class="form-group">
                      <label for="exampletextarea" class="form-label text-dark"
                        >Message</label
                      >
                      <textarea
                        name="text"
                        class="form-control border-0"
                        id="exampletextarea"
                        cols="30"
                        rows="5"
                        placeholder="Message"
                      ></textarea>
                    </div>
                  </div>
                  <div class="col-12 wow fadeIn" data-wow-delay="0.1s">
                    <div
                      class="text-center border border-secondary p-4 my-4 position-relative"
                    >
                      <div
                        class="fw-bold text-primary bg-white d-flex align-items-center justify-content-center position-absolute border-secondary p-2"
                        style="
                          width: 50%;
                          border-style: double;
                          top: 0;
                          left: 50%;
                          transform: translate(-50%, -50%);
                        "
                      >
                        Will you be attending?
                      </div>
                      <div class="mt-4">
                        <div class="row g-4">
                          <div class="col-lg-6">
                            <div class="d-flex justify-content-center">
                              <div class="form-check d-flex">
                                <input
                                  class="form-check-input me-2"
                                  type="radio"
                                  name="flexRadioDefault"
                                  id="flexRadioDefault1"
                                />
                                <label
                                  class="form-check-label text-dark"
                                  for="flexRadioDefault1"
                                >
                                  Accepts With Pleasure!
                                </label>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-6">
                            <div class="d-flex justify-content-center">
                              <div class="form-check d-flex">
                                <input
                                  class="form-check-input me-2"
                                  type="radio"
                                  name="flexRadioDefault"
                                  id="flexRadioDefault1"
                                />
                                <label
                                  class="form-check-label text-dark"
                                  for="flexRadioDefault1"
                                >
                                  Declines With Regret
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-12 text-center wow fadeIn"
                    data-wow-delay="0.1s"
                  >
                    <button
                      class="btn btn-primary btn-primary-outline-0 py-3 px-5"
                    >
                      Submit Now
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="mt-5">
          <div class="row g-5 justify-content-center text-center">
            <div class="col-md-4 wow fadeIn" data-wow-delay="0.1s">
              <div class="p-4 border-secondary" style="border-style: double">
                <h4>Call Us Now</h4>
                <a href="#" class="text-dark">+250 782 761 525</a>
              </div>
            </div>
            <div class="col-md-4 wow fadeIn" data-wow-delay="0.3s">
              <div class="p-4 border-secondary" style="border-style: double">
                <h4>When</h4>
                <p class="mb-0 text-dark">
                  22. September 2024. at 7 o’clock in the afternoon
                </p>
              </div>
            </div>
            <div class="col-md-4 wow fadeIn" data-wow-delay="0.5s">
              <div class="p-4 border-secondary" style="border-style: double">
                <h4>Where</h4>
                <p class="mb-0 text-dark">Kimihurura, Kigali Rwanda.</p>
              </div>
            </div>
            <div class="col-12 wow fadeIn" data-wow-delay="0.2s">
              <div class="border-secondary" style="border-style: double">
                <iframe
                  class="w-100"
                  style="height: 450px; margin-bottom: -6px"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d387191.33750346623!2d-73.97968099999999!3d40.6974881!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c24fa5d33f083b%3A0xc80b8f06e177fe62!2sNew%20York%2C%20NY%2C%20USA!5e0!3m2!1sen!2sbd!4v1694259649153!5m2!1sen!2sbd"
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- RSVP Form End -->

    <!-- Footer Start -->
    <div class="container-fluid footer py-5 wow fadeIn" data-wow-delay="0.1s">
      <div class="container py-5">
        <div class="row g-5 justify-content-center">
          <div class="col-lg-4 text-start">
            <div class="footer-item d-flex flex-column">
              <h4 class="mb-4 text-white">Links</h4>
              <a href="#" class="btn-link"> About</a>
              <a href="#" class="btn-link"> Gallery</a>
              <a href="#" class="btn-link"> Story</a>
              <a href="#" class="btn-link"> Timeline</a>
              <a href="#" class="btn-link"> RSVP</a>
              <a href="#" class="btn-link"> Contact Us</a>
              <a href="#" class="btn-link"> Wsedding-date</a>
            </div>
          </div>
          <div class="col-lg-4 text-center">
            <div class="footer-item">
              <h4 class="mb-4 text-white">
                Dr Karen<strong class="text-primary"> & </strong>Dr Heritier
              </h4>
              <p class="text-white">
                Dolor amet sit justo amet elitr clita ipsum elitr est.Lorem
                ipsum dolor sit amet, consectetur adipiscing elit. Nullam in
                tempor dui, non consectetur enim. Lorem Ipsum is simply dummy
                text of the printing and typesetting industry. Lorem Ipsum has
                been the industry's standard dummy
              </p>
              <div class="btn-link d-flex justify-content-center">
                <a
                  href="#"
                  class="btn btn-md-square btn-light btn-light-outline-0 me-2"
                  ><i class="fab fa-facebook-f"></i
                ></a>
                <a
                  href="#"
                  class="btn btn-md-square btn-light btn-light-outline-0 me-2"
                  ><i class="fab fa-twitter"></i
                ></a>
                <a
                  href="#"
                  class="btn btn-md-square btn-light btn-light-outline-0 me-2"
                  ><i class="fab fa-instagram"></i
                ></a>
                <a
                  href="#"
                  class="btn btn-md-square btn-light btn-light-outline-0 me-0"
                  ><i class="fab fa-linkedin-in"></i
                ></a>
              </div>
            </div>
          </div>
          <div class="col-lg-4 text-end">
            <div class="footer-item d-flex flex-column">
              <h4 class="mb-4 text-white">Follow Us</h4>
              <a href="#" class="btn-link"> Faceboock</a>
              <a href="#" class="btn-link"> Instagram</a>
              <a href="#" class="btn-link"> Twitter</a>
              <h4 class="my-4 text-white">Contact Us</h4>
              <a href="#" class="btn-link"
                ><i class="fas fa-envelope text-secondary me-2"></i>
                info@example.com</a
              >
              <a href="#" class="btn-link"
                ><i class="fas fa-phone text-secondary me-2"></i> (+250) 789 326
                245</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Footer End -->

    <!-- Copyright Start -->
    <div class="container-fluid copyright py-4">
      <div class="container">
        <div class="row g-4 align-items-center">
          <div class="col-md-6 text-center text-md-start mb-md-0">
            <span class="text-light"
              ><a href="#"
                ><i class="fas fa-copyright text-light me-2"></i>Dr Karen & Dr
                Heritier</a
              >, All right reserved.</span
            >
          </div>
          <div class="col-md-6 text-center text-md-end text-white">
            Designed By
            <a class="border-bottom" href="#">Heritier Ganza Tamba</a>
          </div>
        </div>
      </div>
    </div>
    <!-- Copyright End -->

    <!-- Back to Top -->
    <a
      href="#"
      class="btn btn-primary btn-primary-outline-0 btn-md-square back-to-top"
      ><i class="fa fa-arrow-up"></i
    ></a>
  </div>
</template>
